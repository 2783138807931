import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    // {
    //     path: 'authentication',
    //     loadChildren: () => import('../../authentication/authentication.module').then(m => m.AuthenticationModule),
    // } 
];